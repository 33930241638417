import { Component, OnInit, OnDestroy } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FirestoreUtilities } from '../../shared/firestore-utilities';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit, OnDestroy {
  public privacyPolicy = '';
  destroy$ = new Subject();
  constructor(private afs: AngularFirestore) { }

  ngOnInit() {
    this.afs.doc(`staticContents/privacy-policy`).snapshotChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe(content$ => {
        const content = FirestoreUtilities.objectToType(content$);
        this.privacyPolicy = content ? content.value : '';
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
