import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

const thirdPartyReportingProduct = {
    title: `Third Party Delivery Reconciliation`,
    tagLine: `Putting you in the driver's seat for third party delivery account management!`,
    description: `
    <h4><b>Key Features</b></h4>
    <ul>
        <li>Run reports for multiple locations with multiple 3PD's all at once</li>
        <li>POS to 3PD transaction matching <b><i>Powered by DeliverSense AI</i></b></li>
        <li>Sales and tax variance calculations</li>
        <li>Market facilitator tax calculations</li>
        <li>Tax liability analysis</li>
    </ul>
    <section class="section" id="pricing">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-lg-8 mat-elevation-z5">
                    <div class="pricing-plans bg-white text-center p-4 mt-4">
                        <h3 class="plan-title mt-2 mb-4">Pricing</h3>
                        <h4 class="plan-title mt-2 mb-4 text-muted">as low as</h3>
                        <div class="plan-price">
                            <h4 class="mb-3 price"><sup><small>$</small></sup>5</h4>
                            <h5 class="plan-duration text-muted font-18">Per Location Per 3PD</h5>
                        </div>
                        <div class="pricing-content p-4 text-muted mb-2">
                            <p><i class="fas fa-check-circle"></i> Unlimited Transactions</p>
                            <p><i class="fas fa-check-circle"></i> Unlimited Reports</p>
                            <p><i class="fas fa-check-circle"></i> Unlimited Exports</p>
                            <p><i class="fas fa-check-circle"></i> Free transaction file uploads*</p>
                            <p><i class="fas fa-check-circle"></i> AI Powered Transaction Matching</p>
                            <p><i class="fas fa-check-circle"></i> Free Support</p>
                        </div>
                    </div>
                    <p>*One time setup fee for direct transaction system integration.</p>
                </div>
            </div>
        </div>
    </section>
`
};
const exemptionCertProduct = {
    title: `Exempt Sales Management`,
    tagLine: `Simplify, automate, and standardize exempt sales reporting.`,
    description: `
    <h4><b>Key Features</b></h4>
    <ul>
        <li>Integrated certificate documentation upload for each exempt sales.</li>
        <li>Intelligent analytics to quickly identify and resolve undocumented exempt sales.</li>
        <li>Simple reporting and exportation for audits and analysis.</li>
    </ul>
    <section class="section" id="pricing">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-lg-8 mat-elevation-z5">
                    <div class="pricing-plans bg-white text-center p-4 mt-4">
                        <h3 class="plan-title mt-2 mb-4">Pricing</h3>
                        <h4 class="plan-title mt-2 mb-4 text-muted">as low as</h3>
                        <div class="plan-price">
                            <h4 class="mb-3 price"><sup><small>$</small></sup>1</h4>
                            <h5 class="plan-duration text-muted font-18">Per Location</h5>
                        </div>
                        <div class="pricing-content p-4 text-muted mb-2">
                            <p><i class="fas fa-check-circle"></i> Unlimited Transactions</p>
                            <p><i class="fas fa-check-circle"></i> Unlimited Reports</p>
                            <p><i class="fas fa-check-circle"></i> Unlimited Exports</p>
                            <p><i class="fas fa-check-circle"></i> Free transaction file uploads*</p>
                            <p><i class="fas fa-check-circle"></i> 1 GB free document storage**</p>
                            <p><i class="fas fa-check-circle"></i> Free Support</p>
                        </div>
                    </div>
                    <p>*One time setup fee for direct transaction system integration.</p>
                    <p>**Each addition GB of stored documentation will be billed at $2/month.</p>
                </div>
            </div>
        </div>
    </section>
    `
};
const proofOfReviewProduct = {
    title: `Audit Trails`,
    tagLine: `Give your team a better way to manage your review workflow through a centralized cloud based solution.`,
    description: `
    <h4><b>Key Features</b></h4>
    <ul>
    <li>Review documentation organized by entity and project.</li>
    <li>Create customizable review templates.</li>
    <li>Assign comments/tasks and related due dates to team members.</li>
    <li>In app and email notification to track progress.</li>
    <li>Integrated comment chat window.</li>
    </ul>
    <section class="section" id="pricing">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-lg-8 mat-elevation-z5">
                    <div class="pricing-plans bg-white text-center p-4 mt-4">
                        <h3 class="plan-title mt-2 mb-4">Pricing</h3>
                        <div class="plan-price">
                            <h4 class="mb-3 price"><sup><small>$</small></sup>15</h4>
                            <h5 class="plan-duration text-muted font-18">Per User</h5>
                        </div>
                        <div class="pricing-content p-4 text-muted mb-2">
                            <p><i class="fas fa-check-circle"></i> Unlimited Documents</p>
                            <p><i class="fas fa-check-circle"></i> Unlimited Projects</p>
                            <p><i class="fas fa-check-circle"></i> Unlimited comments and chats</p>
                            <p><i class="fas fa-check-circle"></i> Unlimited review document exports</p>
                            <p><i class="fas fa-check-circle"></i> In App Progress Notifications</p>
                            <p><i class="fas fa-check-circle"></i> Free Support</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    `
};
const propertyBillingProduct = {
    title: `Property Tax Manager`,
    tagLine: `Coming Soon...`,
    description: `Property Tax Manager system integrates directly with municipalities across the  U.S. to enable 
    easy management of your property tax liability. Receive alerts when new bills are available, due dates are approaching,
    and even set up accrual schedules.`
};

@Component({
    selector: 'app-product-info-dialog',
    templateUrl: './product-info-dialog.component.html',
    styleUrls: ['./product-info-dialog.component.scss']
})
export class ProductInfoDialogComponent implements OnInit {
    private productName: string;
    public product: { tagLine: string; description: string; title: string };
    public scrollbarOptions = { axis: 'y', theme: 'minimal-dark' };
    constructor(public dialogRef: MatDialogRef<ProductInfoDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.productName = data.productName;
        this.setProduct();
    }

    ngOnInit() {
    }

    private setProduct() {
        switch (this.productName) {
            case '3pdReporting':
                this.product = thirdPartyReportingProduct;
                break;
            case 'exemptionManagement':
                this.product = exemptionCertProduct;
                break;
            case 'proofOfReview':
                this.product = proofOfReviewProduct;
                break;
            case 'propertyBilling':
                this.product = propertyBillingProduct;
                break;
        }
    }
}
