import { Component } from '@angular/core';

@Component({
  selector: 'app-just-deliver',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public scrollbarOptions = { axis: 'yx', theme: 'minimal-dark' };

}
