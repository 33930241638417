import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-policy-dialog',
  templateUrl: './policy-dialog.component.html',
  styleUrls: ['./policy-dialog.component.scss']
})
export class PolicyDialogComponent implements OnInit {
  policyName: any;
  public scrollbarOptions = { axis: 'y', theme: 'minimal-dark' };

  constructor(public dialogRef: MatDialogRef<PolicyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.policyName = data.policyName;
  }
  ngOnInit() {
  }

}
