import { Component, OnInit, ElementRef } from '@angular/core';
import { environment } from '../../environments/environment';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { ProductInfoDialogComponent } from './product-info-dialog/product-info-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { PolicyDialogComponent } from '../policies/policy-dialog/policy-dialog.component';
import { element } from 'protractor';
import { ActivatedRoute } from '@angular/router';

declare const jQuery: any;
declare const $: any;

@Component({
    selector: 'app-landing',
    templateUrl: './landing.component.html',
    styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
    public contactForm: FormGroup;
    public incompleteForm: boolean;
    public subjects = [
        'Learn More',
        'Join Beta Program',
        'Custom Tool Creation',
        'Partnerships',
        'Other'
    ];
    constructor(private fb: FormBuilder,
        private snackBar: MatSnackBar,
        private activatedRoute: ActivatedRoute,
        private dialog: MatDialog,
        private elem: ElementRef,
        private afs: AngularFirestore) {
    }

    ngOnInit() {
        this.activatedRoute.queryParams.subscribe(queryParams$ => {
        })
        this.initStickyMenu();
        this.initSmoothLink();
        this.initMfpvideo();
        // this.initContact();
        this.initBacktoTop();
        this.setupContactForm();
    }

    private setupContactForm() {
        this.contactForm = this.fb.group({
            name: new FormControl('', Validators.required),
            company: new FormControl(''),
            subject: new FormControl('Learn More', Validators.required),
            email: new FormControl('', [Validators.required, Validators.email]),
            message: new FormControl('', [Validators.required, Validators.maxLength(300)])
        });
    }

    initStickyMenu() {
        $(window).scroll(() => {
            const scroll = $(window).scrollTop();

            if (scroll >= 50) {
                $('.sticky').addClass('nav-sticky');
            } else {
                $('.sticky').removeClass('nav-sticky');
            }
        });
    }


    initSmoothLink() {
        $('.navbar-nav a').on('click', (event) => {

            event.preventDefault();
        });
    }

    initMfpvideo() {
        $('.video-play-icon').magnificPopup({
            disableOn: 700,
            type: 'iframe',
            mainClass: 'mfp-fade',
            removalDelay: 160,
            preloader: false,
            fixedContentPos: false
        });
    }

    initContact() {

        $('#contact-form').submit(() => {

            const action = $(this).attr('action');

            $('#message').slideUp(750, () => {
                $('#message').hide();

                $('#submit')
                    .before('')
                    .attr('disabled', 'disabled');

                $.post(action, {
                    name: $('#name').val(),
                    email: $('#email').val(),
                    comments: $('#comments').val(),
                },
                    (data) => {
                        document.getElementById('message').innerHTML = data;
                        $('#message').slideDown('slow');
                        $('#cform img.contact-loader').fadeOut('slow', function () {
                            $(this).remove();
                        });
                        $('#submit').removeAttr('disabled');
                        if (data.match('success') != null) {
                            $('#cform').slideUp('slow');
                        }
                    }
                );

            });

            return false;

        });
    }

    initBacktoTop() {
        $(window).scroll(() => {
            if ($(this).scrollTop() > 100) {
                $('.back-to-top').fadeIn();
            } else {
                $('.back-to-top').fadeOut();
            }
        });
        $('.back-to-top').click(() => {
            $('html, body').animate({ scrollTop: 0 }, 1000);
            return false;
        });
    }

    async submitContactForm() {
        if (this.contactForm.valid) {
            const form = this.contactForm.value;
            try {
                await this.afs.collection('inquiries').add({
                    name: form.name,
                    email: form.email,
                    subject: form.subject,
                    company: form.company ? form.company : '',
                    message: form.message,
                });
                this.snackBar.open('Thank you we have received your inquiry, and will respond shortly.', 'Dismiss',
                    {
                        duration: 6000
                    });
                this.contactForm.reset();
            } catch (err) {
                this.snackBar.open('Opps... something went wrong. Please refresh the page and submit your inquiry again.', 'Dismiss', {
                    duration: 5000
                });

            }
        } else {
            this.incompleteForm = true;
        }
    }

    markFields(form) {
        Object.keys(form.controls).forEach(field => {
            form.get(field).markAsTouched();
        });
    }

    goToApp() {
        window.location.replace(environment.appLocation);
    }
    async promptContact() {
        this.snackBar.open('Thanks for your interest is becoming a user of DeliverSense. A representative will be with you shortly to get your account setup!', 'Dismiss', {
            duration: 10000
        });
        this.openChat();
        this.afs.collection('metrics').add({ type: 'registerClicked' });
    }
    openProductDescription(productName: string) {
        this.dialog.open(ProductInfoDialogComponent, {
            autoFocus: false,
            data: { productName }
        });
    }
    openPolicyDialog(policyName: string) {
        this.dialog.open(PolicyDialogComponent, {
            data: {
                policyName
            }
        });
    }
    openChat() {
        let element = document.getElementsByClassName('silc-btn');
        if (element) {
            // @ts-ignore
            element.item(0).click();
        }
    }
}

