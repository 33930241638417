import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TermsOfServiceComponent } from './terms-of-service/terms-of-service.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MalihuScrollbarModule } from 'ngx-malihu-scrollbar';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatSnackBarModule, MatDialogModule, MatFormFieldModule, MatDividerModule, MatButtonModule, MatIconModule } from '@angular/material';
import { VideoPlayerModule } from '../components/video-player/video-player.module';
import { LayoutModule } from '@angular/cdk/layout';
import { PolicyDialogComponent } from './policy-dialog/policy-dialog.component';

@NgModule({
  declarations: [TermsOfServiceComponent, PrivacyComponent, PolicyDialogComponent],
  entryComponents: [PolicyDialogComponent],
  imports: [
    CommonModule,
        FlexLayoutModule,
        MalihuScrollbarModule.forRoot(),
        ScrollToModule.forRoot(),
        ReactiveFormsModule,
        FormsModule,
        LayoutModule,
        MatSnackBarModule,
        MatDialogModule,
        MatFormFieldModule,
        MatDividerModule,
        MatButtonModule,
        MatIconModule,
        VideoPlayerModule
  ]
})
export class PoliciesModule { }
