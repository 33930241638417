import { Component, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { VgAPI } from 'videogular2/compiled/core';
import { BehaviorSubject, combineLatest } from 'rxjs';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss']
})
export class VideoPlayerComponent implements OnInit {
  videoUrl: any;
  isPlaying = new BehaviorSubject(false);
  api: VgAPI;

  constructor(private storage: AngularFireStorage) { this.getDownloadUrl(); }

  ngOnInit() {
  }

  async getDownloadUrl() {
    this.videoUrl = await this.storage.storage.ref('/static/promo-1-final.mp4').getDownloadURL();
  }
  onPlayerReady(api: VgAPI) {
    this.api = api;
    this.api.getDefaultMedia().subscriptions.playing.subscribe(
      () => {
        // Set the video to the beginning
        this.isPlaying.next(true);
      });
    combineLatest([
      this.api.getDefaultMedia().subscriptions.pause,
      this.api.getDefaultMedia().subscriptions.ended
    ])
      .subscribe(
        () => {
          // Set the video to the beginning
          this.isPlaying.next(false);
        });
  }
}
