import { Component, OnInit, OnDestroy } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FirestoreUtilities } from '../../shared/firestore-utilities';

@Component({
  selector: 'app-terms-of-service',
  templateUrl: './terms-of-service.component.html',
  styleUrls: ['./terms-of-service.component.scss']
})
export class TermsOfServiceComponent implements OnInit, OnDestroy {
  public termsOfService = '';
  destroy$ = new Subject();
  constructor(private afs: AngularFirestore) { }

  ngOnInit() {
    this.afs.doc(`staticContents/terms-of-service`).snapshotChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe(content$ => {
        const content = FirestoreUtilities.objectToType(content$);
        this.termsOfService = content ? content.value : '';
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
