import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LandingComponent} from './landing/landing.component';
import { PrivacyComponent } from './policies/privacy/privacy.component';
import { TermsOfServiceComponent } from './policies/terms-of-service/terms-of-service.component';


const routes: Routes = [
    {path: '', component: LandingComponent},
    {path: 'privacy-policy', component: PrivacyComponent},
    {path: 'terms-of-service', component: TermsOfServiceComponent}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'top'})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
