import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LandingComponent} from './landing.component';
import {ScrollToModule} from '@nicky-lenaers/ngx-scroll-to';
import {MalihuScrollbarModule} from 'ngx-malihu-scrollbar';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule, MatSnackBarModule} from '@angular/material';
import { ProductInfoDialogComponent } from './product-info-dialog/product-info-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {FlexLayoutModule} from '@angular/flex-layout';
import { VideoPlayerModule } from '../components/video-player/video-player.module';
import { PoliciesModule } from '../policies/policies.module';
import { RouterModule } from '@angular/router';
@NgModule({
    declarations: [LandingComponent, ProductInfoDialogComponent],
    entryComponents: [ProductInfoDialogComponent],
    imports: [
        CommonModule,
        FlexLayoutModule,
        MalihuScrollbarModule.forRoot(),
        ScrollToModule.forRoot(),
        ReactiveFormsModule,
        FormsModule,
        MatSnackBarModule,
        MatDialogModule,
        MatFormFieldModule,
        MatDividerModule,
        MatButtonModule,
        MatIconModule,
        VideoPlayerModule,
        PoliciesModule,
        RouterModule
    ]
})
export class LandingModule {
}
