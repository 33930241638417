import {AngularFirestore, DocumentChangeAction} from "@angular/fire/firestore";
import {combineLatest, from, Observable, of, Subject} from "rxjs";
import {combineAll, map, switchMap, takeUntil} from "rxjs/operators";
import * as firebase from 'firebase';
import {AngularFireStorage} from "@angular/fire/storage";

declare const inflection: any;

export class FirestoreUtilities {
  public static mapToType(actions) {
    if (actions.length > 0) {
      return actions.map(a => {
        if (a) {
          if (a.payload.doc) {
            const data = a.payload.doc ? a.payload.doc.data() : a.payload.data();
            const id = a.payload.doc ? a.payload.doc.id : a.payload.id;
            return {id, ...data};
          } else if (a.payload) {
            const data = actions.payload.data();
            const id = actions.payload.id;
            return {id, ...data};
          }
        }
      });
    } else {
      return [];
    }
  }

  public static objectToType(actions) {
    if (actions && actions.payload.exists) {
      const data = actions.payload.data();
      const id = actions.payload.id;
      return {id, ...data};
    } else if (actions && actions.payload.doc && actions.payload.doc.exists) {
      const data = actions.payload.doc.data();
      const id = actions.payload.doc.id;
      return {id, ...data};
    } else {
      return null;
    }
  }

  public static mergeToType(actions) {
    const resultArray = [];
    if (actions instanceof Array) {
      actions.forEach(action => {
        resultArray.push(FirestoreUtilities.objectToType(action));
      })
    } else {
      resultArray.push(FirestoreUtilities.objectToType(actions));
    }
    return resultArray;
  }

  public static mergeCollectionToType(actions) {
    const resultArray = [];
    if (actions instanceof Array) {
      actions.forEach(action => {
        if (action instanceof Array) {
          action.forEach(item => {
            if (item) {
              const data = item.payload.doc ? item.payload.doc.data() : item.payload.data();
              const id = item.payload.doc ? item.payload.doc.id : item.payload.id;
              resultArray.push({id, ...data});
            }
          });
        } else if (action && action.payload) {

        }
      })
    }
    return resultArray;
  }

  public static getSubcollectionParent(parentType: string, childDoc: DocumentChangeAction<any>) {
    // Loop up through the parent doc references until you get to the user doc
    let parentRef: any = childDoc.payload.doc.ref.parent;
    while (parentRef.path && parentRef.parent && parentRef.parent.id !== parentType) {
      parentRef = parentRef.parent;
    }
    return parentRef ? parentRef.id : null;
  }
  public static fetchChildrenFromParentCollection(parentCollection: any[],
                                                  childCollectionName: string,
                                                  childKeyInParent: string,
                                                  afs: AngularFirestore,
                                                  destroySubject: Subject<any>) {
    if (parentCollection.length > 0) {
      const childDocumentRequests = parentCollection.map(parent => {
        return afs.doc(`${childCollectionName}/${parent[childKeyInParent]}`).snapshotChanges()
      }).filter(req => !!req);
      return from(childDocumentRequests)
        .pipe(combineAll(), takeUntil(destroySubject), map(children$ => {
          return FirestoreUtilities.mergeToType(children$);
        }));
    } else {
      return of([]);
    }
  }

  public static queryChildrenFromParentCollection(parentCollection: any[],
                                                  childCollectionName: any,
                                                  destroySubject: Subject<any>,
                                                  afs: AngularFirestore,
                                                  queryParams: WhereQueryParams[]) {
    if (parentCollection.length > 0) {
      const childDocumentRequests = parentCollection.map(parent => {
        // @ts-ignore
        return afs.collection(childCollectionName, ref => {
          queryParams.forEach(param => {
            switch (param.type) {
              case 'where':
                ref.where(param.key, param.operator, parent[param.parentValueKey]);
                break;
              case 'orderBy':
                ref.orderBy(param.key);
                break;
            }
            return ref;
          });
        }).snapshotChanges()
      }).filter(req => !!req);
      return from(childDocumentRequests)
        .pipe(combineAll(), takeUntil(destroySubject), map(children$ => {
          return FirestoreUtilities.mergeCollectionToType(children$);
        }));
    } else {
      return of([]);
    }
  }

  public static async deleteImage(path) {
    const storageRef = firebase.storage().ref();
    return storageRef.child(path).delete();
  }

  public static async getDownloadUrlFromPath(storage: AngularFireStorage, path: string ) {
    return await storage.storage.ref(path).getDownloadURL();
  }
}


export class WhereQueryParams {
  type: string;
  key: string;
  operator: any;
  parentValueKey?: string
}

